@import url(https://fonts.googleapis.com/css2?family=Anybody:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
/*   background: #e9f7e1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
box-sizing: border-box;
}

    body {
        margin: 0;
        height: 100vh;
    }

    .nav {
        background-color: rgb(74, 158, 107);
        color: #172215;
        display: flex;
        justify-content: space-between;
        align-items: initial;
        grid-gap: 2rem;
        gap: 2rem;
        width: 100%;
    }

    .nav ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        grid-gap: 0;
        gap: 0;
        color: white;
    }

    .nav li {
        text-decoration: none;
        color: black;
        height: 100%;
        padding: 0 1rem;
        display: flex;
    }
    .nav li.active {
        background-color: rgb(39, 85, 61);
    }

    .nav li:hover {
        background-color: rgb(142, 252, 142);
        color: black;
    }
    .nav a {
        text-decoration: none;
        color: white;
        padding: 0 1rem;
        align-items: center;
        display: flex;
    }
    .nav a:hover {
        text-decoration: none;
        color: rgb(46, 96, 65);
        padding: 0 1rem;
        align-items: center;
        display: flex;
    }

    .nav-logo {
        background: white;
    }
    
    .nav-logo h1 {
        font-weight: bold;
        color: rgb(10, 10, 10);
        background-color: white;
        padding: 20px;
        margin: 0;
        z-index: 11;
        position: relative;
        font-family: Aldrich;
        text-transform: Uppercase;
        font-size: 1.23em;
        margin-top: 13px;
    }
    
    .nav-logo h1::before {
        content: " ";
        top: -43px;
        z-index: 11;
        left: 107%;
        width: 0;
        height: 0;
        background-color: transparent;
        position: absolute;
        border-right: 64px solid transparent;
        border-top: 70px solid transparent;
        border-bottom: 64px solid white;
        border-left: 77px solid white;
    }

    .claim {
        font-style: italic;
        margin-bottom: -40px;
        margin-top: 72px;
        font-size: 22px;
        color: rgb(110, 201, 169);
        font-family: Calibri;
    }

    .container {
        background: white;
        display: flex;
        margin: 0;
        padding: 2rem;
        justify-content: center;
    }

    .menu-add-results a{
        color: rgb(32, 117, 33);
        margin-bottom: 10px;
        display: flex;
        justify-content:flex-end;
        align-items: left;
        margin-top: 0;
        margin-left: 25px;

    }

    .player-team-chip {
        margin: 2px;
    }

    .title-select-players-black {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 1.16em;
        background: black;
        color: white;
        border-radius: 4px;
        width: 300px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .title-select-players-white {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 1.16em;
        border-color: black;
        background: white;
        border-radius: 4px;
        border: 2px solid black;
        width: 300px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .line-hor-form {
        color: transparent;
        border-color: rgb(203, 233, 210);
    }

    .position-clasificaction {
        font-size: .78em;
        font-weight: bold;
        text-align: center;
        background: rgb(182, 206, 192);
        color: white;
        border-radius: 25px;
        width: 21px;
        height: 21px;
        position: absolute;
        left: initial;
        margin-left: 15px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .separetor {
        font-size:1.5em;
        color: rgb(5, 162, 73);
        margin-left: 3px;
        margin-right: 3px;
    }


    .score-column--goals {
        font-size: .86em;
        color: rgb(78, 78, 78);
        margin-left: 0;
        width:50px;
        background-color: grayscale;
        position: relative;
        display: inline-flex;
    }


    .score-column::first-letter {
        color: blue;
    }


    .App {
        text-align: center;
    }
    
    .App-logo {
        height: 2.25rem;
        pointer-events: none;
        background-color: white;
        text-align: left;
        box-shadow: 2px 3px 4px solid rgb(253, 253, 253);
        z-index: 11;
        display: block;
        margin-top: -10px;
        margin-left: -13px;
    }
    
    .App-logo::before {
        content: "";
        width: 150px;
        height: 160px;
        background: white;
        border-radius: 15px;
        position: absolute;
    }
  
  
    h1 {
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 24px;
        font-family: 'Aldrich', ;
    }
    
    h3 {
        font-size: 1em;
        font-weight: normal;
        background-color: #9cca55;
        background-image: linear-gradient(45deg, #9cca55, #7cab34);
        color: white;
        padding: 5px 8px;
        position: relative;
        display: inline;
        border-radius:2px;
    }
    
    .root-loading {
        display: flex;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.477);
        top: 120px;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
  
    .icon-futbol-o {
        background: white;
        overflow: hidden;
        fill: white;
    }

    .MuiChip-label.MuiChip-labelSmall.css-wjsjww-MuiChip-label {
        font-size: .71rem  !important;
    }
    
    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
        -webkit-animation: App-logo-spin infinite 17s linear;
                animation: App-logo-spin infinite 17s linear;
        }
    }
    
    .App-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: calc(10px + 2vmin);
    }
    
    .css-hyum1k-MuiToolbar-root {
        min-height: 80px !important;
        padding-right: 0 !important;
    }

    .css-cveggr-MuiListItemIcon-root {
        min-width: 45px;
    }
    
    
    @-webkit-keyframes App-logo-spin {
        from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
    }
    
    
    @keyframes App-logo-spin {
        from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
    }
  
  
    .button-primary {
        background-color: rgb(32, 117, 33);
    }
    
    .hidden-input {
        display: none !important;
    }
    
    .background-form {
        background-color:white;
    }
    
    .listIconStyle {
        min-width: 40px !important;
    }
    
    .item-list--jugadores{
        font-size: .86em !important;
        font-weight: 100 !important;
    } 

    .content-pagina-inicio {  
        padding: 30px;
        text-align: left;
    }

    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
        padding-right: 0;
    }
    .MuiTable-root {
        max-width: 1200px;
    }

  @media (max-width: 600px) {
    .nav {
        background-color: transparent;
        justify-content: center;
    }

    .nav-logo h1::before {
      content: none;
    }
    .nav-logo {
        margin-left: -25px;
    }

    .container{
        display: block;
    }

    .content-pagina-inicio {  
        padding: 10px;
        text-align: left;
    }
      
    .content-select-add-game {
        align-items: center;
    }

    header.MuiPaper-root  {
        background-color: white !important;
        border-bottom: 1px solid black;
    }



  }
